import request from "@/utils/request";

export function getPlayerSeasonList(params) {
  return request({
    url: "/v2/player_season_list/",
    method: "get",
    params,
  });
}

export function getPlayerRankingData(params) {
  return request({
    url: "/v2/player_ranking_data/",
    method: "get",
    params,
  });
}

export function getPlayerSeasonData(params) {
  return request({
    url: "/v2/player_season_data/",
    method: "get",
    params,
  });
}

export function getPlayerGameData(params) {
  return request({
    url: "/player_game_data/",
    method: "get",
    params,
  });
}
