<template lang="pug">
.advanced
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(
              :value="index",
              :key="index",
              v-for="(item, index) in leagueData.info.seasons"
            ) {{ item.name }}
  v-container.table_container
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th.bdr(colspan="3") 團隊進攻效率(100回合)
              th.bdr(colspan="7")
              th.bdr(colspan="3") 對手進攻效率(100回合)
              th.bdr(colspan="7")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(v-for="(title, index) in headTitleList") {{ title.title }}
          tbody
            tr(v-for="(game, index) in playerSeasonAllData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="'整季'"
              )
              td.bdr {{ game.on_off_court.on_court.ppp_rounds_100 }}
              td.bdr {{ game.on_off_court.off_court.ppp_rounds_100 }}
              td.bdr {{ game.eff.ppp_rounds_100 }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.pts_per_36.toFixed(1) }}
              td.bdr {{ game.on_off_court.on_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.pts_per_36.toFixed(1) }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th.bdr(colspan="3") 團隊進攻效率(100回合)
              th.bdr(colspan="7")
              th.bdr(colspan="3") 對手進攻效率(100回合)
              th.bdr(colspan="7")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(v-for="(title, index) in headTitleList") {{ title.title }}
          tbody
            tr(v-for="(game, index) in playerSeasonDateData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split_info.name"
              )
              td.bdr {{ game.on_off_court.on_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.pts_per_36.toFixed(1) }}
              td.bdr {{ game.on_off_court.on_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.pts_per_36.toFixed(1) }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th.bdr(colspan="3") 團隊進攻效率(100回合)
              th.bdr(colspan="7")
              th.bdr(colspan="3") 對手進攻效率(100回合)
              th.bdr(colspan="7")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(v-for="(title, index) in headTitleList") {{ title.title }}
          tbody
            tr(
              v-for="(quarter, index) in playerSeasonQuarterCount",
              :key="index",
              v-if="quarter != 1"
            )
              sticky-column.bdl.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="getQuarterText(quarter - 1)"
              )
              td.bdr {{ playerSeasonQuarterData[0].on_off_court.on_court[quarter - 1].ppp_rounds_100.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].on_off_court.off_court[quarter - 1].ppp_rounds_100.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].eff[quarter - 1].ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (playerSeasonQuarterData[0].advancement[quarter - 1].off_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_man[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_zone[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_transition[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_second_chance[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_transition[quarter - 1].pts_per_36.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].vs_defense.vs_second_chance[quarter - 1].pts_per_36.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].on_off_court.on_court[quarter - 1].opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].on_off_court.off_court[quarter - 1].opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].eff[quarter - 1].opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (playerSeasonQuarterData[0].advancement[quarter - 1].def_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_man[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_zone[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_transition[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_second_chance[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_transition[quarter - 1].pts_per_36.toFixed(1) }}
              td.bdr {{ playerSeasonQuarterData[0].opp_vs_defense.opp_vs_second_chance[quarter - 1].pts_per_36.toFixed(1) }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th.bdr(colspan="3") 團隊進攻效率(100回合)
              th.bdr(colspan="7")
              th.bdr(colspan="3") 對手進攻效率(100回合)
              th.bdr(colspan="7")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(v-for="(title, index) in headTitleList") {{ title.title }}
          tbody
            tr(v-for="(game, index) in playerSeasonWinLoseData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split == 'WIN' ? '勝' : '負'"
              )
              td.bdr {{ game.on_off_court.on_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.pts_per_36.toFixed(1) }}
              td.bdr {{ game.on_off_court.on_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.pts_per_36.toFixed(1) }}
    v-row.table_row
      v-col.table.table_col.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th.bdr(colspan="3") 團隊進攻效率(100回合)
              th.bdr(colspan="7")
              th.bdr(colspan="3") 對手進攻效率(100回合)
              th.bdr(colspan="7")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(v-for="(title, index) in headTitleList") {{ title.title }}
          tbody
            tr(v-for="(game, index) in playerSeasonVSTeamData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="'vs. ' + game.info.split_info.name"
              )
              td.bdr {{ game.on_off_court.on_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_second_chance.pts_per_36.toFixed(1) }}
              td.bdr {{ game.on_off_court.on_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.on_off_court.off_court.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ game.eff.opp_ppp_rounds_100.toFixed(1) }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.ppp.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_transition.pts_per_36.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_second_chance.pts_per_36.toFixed(1) }}
</template>
<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getLeagueList } from "@/api/league";
import { getPlayerSeasonData } from "@/api/player";

export default {
  name: "LeaguePlayerTeamEffect",
  components: {
    StickyColumn,
  },
  data() {
    return {
      league: this.$route.params.league,
      leagueData: {
        info: {},
      },
      playerID: this.$route.params.id,
      seasonInquiryType: 0,

      headTitleList: [
        {
          title: "場上",
          divider: false,
        },
        {
          title: "場下",
          divider: false,
        },
        {
          title: "影響",
          divider: true,
        },
        {
          title: "進攻籃板率",
          divider: true,
        },
        {
          title: "盯人進攻效率",
          divider: true,
        },
        {
          title: "區域進攻效率",
          divider: true,
        },
        {
          title: "快攻效率",
          divider: true,
        },
        {
          title: "二波進攻效率",
          divider: true,
        },
        {
          title: "快攻得分 per 36",
          divider: true,
        },
        {
          title: "二波得分 per 36",
          divider: true,
        },
        {
          title: "場上",
          divider: false,
        },
        {
          title: "場下",
          divider: false,
        },
        {
          title: "影響",
          divider: true,
        },
        {
          title: "對手進攻籃板率",
          divider: true,
        },
        {
          title: "對手盯人進攻效率",
          divider: true,
        },
        {
          title: "對手區域進攻效率",
          divider: true,
        },
        {
          title: "對手快攻效率",
          divider: true,
        },
        {
          title: "對手二波進攻效率",
          divider: true,
        },
        {
          title: "對手快攻得分 per 36",
          divider: true,
        },
        {
          title: "對手二波得分 per 36",
          divider: true,
        },
      ],

      playerSeasonAllData: [],
      playerSeasonDateData: [],
      playerSeasonQuarterData: [],
      playerSeasonQuarterCount: 0,
      playerSeasonWinLoseData: [],
      playerSeasonVSTeamData: [],
    };
  },
  watch: {
    seasonInquiryType() {
      this.playerSeasonAllData = [];
      this.playerSeasonDateData = [];
      this.playerSeasonQuarterData = [];
      this.playerSeasonQuarterCount = 0;
      this.playerSeasonWinLoseData = [];
      this.playerSeasonVSTeamData = [];

      this.getPlayerSeasonAllData();
      this.getPlayerSeasonDateData();
      this.getPlayerSeasonQuarterData();
      this.getPlayerSeasonWinLoseData();
      this.getPlayerSeasonVSTeamData();
    },
  },
  created() {
    this.getLeagueList();
  },
  methods: {
    getLeagueList() {
      getLeagueList().then((response) => {
        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name.indexOf(this.league) != -1) {
            this.leagueData = Object.assign({}, temp);
          }
        }
        this.getPlayerSeasonAllData();
        this.getPlayerSeasonDateData();
        this.getPlayerSeasonQuarterData();
        this.getPlayerSeasonWinLoseData();
        this.getPlayerSeasonVSTeamData();
      });
    },
    getPlayerSeasonAllData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, on_off_court, eff, advancement, vs_defense, opp_vs_defense",
        player_id: this.playerID,
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonAllData = response.data;
      });
    },
    getPlayerSeasonDateData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, on_off_court, eff, advancement, vs_defense, opp_vs_defense",
        player_id: this.playerID,
        split_type: "DATE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonDateData = response.data;
      });
    },
    getPlayerSeasonQuarterData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, on_off_court, eff, advancement, vs_defense, opp_vs_defense",
        player_id: this.playerID,
        show_all_quarters: true,
        split_type: "NONE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonQuarterData = response.data;
        this.playerSeasonQuarterCount =
          this.playerSeasonQuarterData[0].eff.length;
      });
    },
    getPlayerSeasonWinLoseData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, on_off_court, eff, advancement, vs_defense, opp_vs_defense",
        player_id: this.playerID,
        split_type: "WIN_LOSE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonWinLoseData = response.data;
      });
    },
    getPlayerSeasonVSTeamData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, on_off_court, eff, advancement, vs_defense, opp_vs_defense",
        player_id: this.playerID,
        split_type: "VS_TEAM",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonVSTeamData = response.data;
      });
    },
    getQuarterText(count) {
      if (count == 1) {
        return "第一節";
      } else if (count == 2) {
        return "第二節";
      } else if (count == 3) {
        return "第三節";
      } else if (count == 4) {
        return "第四節";
      } else {
        return "OT" + (count - 4);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";

.table_container {
  margin-top: 20px;
}

// ::v-deep .v-input--selection-controls__input input[role="radio"] {
//   opacity: 1;
// }

// ::v-deep .v-input--selection-controls__input {
//   height: 18px;
// }

.table_row {
  padding: 15px;
}
.table_col {
  padding: 0;
}

.tab_container {
  position: relative;
  margin-top: 100px;
  padding: 0;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    bottom: 0;
    height: 3px;
    left: 0;
    background: $paleGray;
  }
}

table {
  text-align: center;
}
</style>
